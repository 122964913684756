// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'http://189.1.174.141:57776/monitor/api/'
};
// local    - http://localhost:52773
// dev      - http://189.1.174.141:57776
// homolog  - https://homolog.omnilinker.cloud/iam/services/rest/irishom
// qa       - https://homolog.omnilinker.cloud/iam/services/rest/irisqa
// prod     - http://service.omnilinker.cloud:51775


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
